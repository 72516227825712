import React, { Component }  from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import * as locale from 'utils/locale';
import * as strapi from 'utils/strapi';
import * as popupActions from 'redux/actions/popupActions';

import GameButton from 'components/general/GameButton';
import ScrollingDiv from 'components/general/ScrollingDiv/ScrollingDiv';

import './Footer.scss';
import FloatingButton from '../../general/FloatingButton';

const mapDispatchToProps = dispatch => ({
    openPopup: (content, options = {}) => dispatch(popupActions.openPopup(content, options)),
    closePopup: () => dispatch(popupActions.closePopup()),
});

class Footer extends Component {
	render() {
		//let buttonText = strapi.data.getText('home.footer.ctaButton');
		//let buttonText1 = buttonText.split(' ')[0];
		//let buttonText2 = buttonText.split(' ').splice(1, 999).join(' ');

		//let copyright = strapi.data.getText('home.footer.copyright').replace('{date}', new Date().getFullYear());
		return (
			<div className="static-footer">
				<div className="content">
					<div className="column logo-column">
						<img className="logo" src={"/images/logos/go-secondaire_logo_jaune_"+locale.getLang()+".svg"} alt="Go secodaire"/>
					</div>
					{/*
					<div className="column">
						<h3><Text slug="home.footer.team"/></h3>
						<ul className="table-members">
							{this.props.partners.map((partner, i) => <li key={i}><a href={partner.url} target="_blank" rel="noopener noreferrer">{partner.name}</a></li>)}
						</ul>
					</div>
					*/}
					<div className="column">
						{/*<h3 dangerouslySetInnerHTML={{__html:strapi.data.getText('home.footer.rrmTitle').replace(/(?:\r\n|\r|\n)/g, '<br/>')}} />*/}
						<a href={strapi.data.getText('general.rrmLink')} target="_blank" rel="noopener noreferrer">
							<img className="logo-rrm" src="/images/static/Logo_RRM_15ans_BLANC.svg" alt="Réseau réussite Montréal"/>
						</a>

						
					</div>
					<div className="column">
						{/*<h3>{strapi.data.getText('home.footer.csTitle')}</h3>*/}
						<div dangerouslySetInnerHTML={{__html:strapi.mdToHtml(strapi.data.getText('home.footer.csList'))}} />
					</div>
					
					<div className="column">
						<div>
							<h3>{strapi.data.getText('home.footer.thanksQuebec')}</h3>
							<img className="logo-qc" src="/images/static/QUEBEC_blanc_no_padding.svg" alt="Gouvernement du Québec"/>
						</div>
					</div>
				</div>

				<div className="static-footer__links">
					<FloatingButton animation={1}>
						<div target="_blank" rel="noopener noreferrer" className="cta" onClick={this.onAboutCtaClick.bind(this)}>
							{strapi.data.getText('home.footer.ctaButton')}
						</div>
					</FloatingButton>
					<FloatingButton animation={2}>
						<div target="_blank" rel="noopener noreferrer" className="cta" onClick={this.onLinksCtaClick.bind(this)}>
							{strapi.data.getText('home.footer.links')}
						</div>
					</FloatingButton>
				</div>
			</div>
		);
	}

	componentDidMount() {
		if(this.props.location.hash === '#about') {
			this.onAboutCtaClick();
		}
	}

	onAboutCtaClick(e = null) {
		let html = strapi.mdToHtml(strapi.data.getText('home.about.popup'));

		if(e !== null) {
			e.preventDefault();
		}

		this.props.openPopup(
			<ScrollingDiv>
				<div dangerouslySetInnerHTML={{__html:html}} />
			</ScrollingDiv>,
			{
				overlayClick: true,
				contentStyle: {
                    padding: '6em 5em 7em',
				},
				actionButtons: [
                    {
                        content: (
                            <GameButton fontSize="2em" onClick={() => {
								this.props.closePopup();
							}}>{strapi.data.getText('general.close')}</GameButton>
                        ),
                    }
				],
				autoAdjust: false,
				responsiveElementOptions: {
					fontSizeRef: 'height',
					cssPosition: 'fixed',
					ratio: null,
					width: "800px",
					height: "800px",
					maxWidth: "80%",
					maxHeight: "90%",
				}
			}
		);
	}

	onLinksCtaClick(e = null) {
		let html = strapi.mdToHtml(strapi.data.getText('home.links.popup'));

		if(e !== null) {
			e.preventDefault();
		}

		this.props.openPopup(
			<ScrollingDiv>
				<div style={{textAlign: "left"}} dangerouslySetInnerHTML={{__html:html}} />
			</ScrollingDiv>,
			{
				overlayClick: true,
				contentStyle: {
                    padding: '6em 5em 7em',
				},
				actionButtons: [
                    {
                        content: (
                            <GameButton fontSize="2em" onClick={() => {
								this.props.closePopup();
							}}>{strapi.data.getText('general.close')}</GameButton>
                        ),
                    }
				],
				autoAdjust: false,
				responsiveElementOptions: {
					fontSizeRef: 'height',
					cssPosition: 'fixed',
					ratio: null,
					width: "800px",
					height: "800px",
					maxWidth: "80%",
					maxHeight: "90%",
				}
			}
		);
	}
}

export default withRouter(connect(null, mapDispatchToProps)(Footer));